export const capitalizeString = text => {
  const words = text.split(' ');
  words.forEach((word, idx) => {
    words[idx] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return words.join(' ');
};

const epsToList = [2, 5, 7, 14, 16, 18, 19, 20, 51, 9, 32]; // In this array is the list of EPS Ids to show in the view.

export const selectEpsToShow = data => {
  const epsLis = data.filter(d => epsToList.some(e => e === d.id));
  return epsLis;
};
